import { Trans } from '@lingui/macro'
import { Box, Dialog } from '@mui/material'
import { Pending } from 'components/Button'
import NumericalInput from 'components/NumericalInput'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { fromWei } from 'utils/bn'

import { StyledInputBox } from '../InfoDetail/Swap'
import { StyledCreateBtn } from '.'

// const StyledDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiPaper-root': {
//     margin: 0,
//     width: '500px',
//     padding: '16px',
//     borderRadius: '16px',
//     boxShow: '5px 4px 0px 0px #00000040',
//   },
//   '& .MuiDialogActions-root': {},
// }))

const StyledDialog = styled(Dialog)`
  background: rgba(0, 0, 0, 0.8);
  .MuiPaper-root {
    margin: 0;
    padding: 16px;
    width: 500px;
    max-width: 100%;
    overflow-x: hidden;
    background-color: ${({ theme }) => theme.themeBg};
    border-radius: 16px;
    border: 1px solid #1ea7e4;
    // border: 1px solid;
    // border-image-slice: 1;
    // border-image-source: linear-gradient(180deg, #1ea7e4 0%, #01bbbe 100%);
    box-shadow: 5px 4px 0px 0px #00000040;
  }
  .MuiDialog-root {
  }
`

const StyledTitle = styled.span`
  color: ${({ theme }) => theme.textPrimary};
  font-weight: 700;
  font-size: 16px;
`

export default function ConfirmDialog({
  open,
  handleClose,
  attemptingTxn,
  handlerCreate,
  buyAmount,
  onUserInput,
  createFee,
  name,
  maxBuy,
}: any) {
  return (
    <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <Box>
        <StyledTitle>
          Choose how many <span className="active-gradient-text">{name}</span> you want to buy (optional)
        </StyledTitle>
      </Box>
      <ThemedText.TextSecondary fontSize={12} fontWeight={700} color="referralCode" mt="8px">
        <Trans>
          tip: its optional but buying a small amount of coins helps protect your coin from snipers. Maximum buy amount:{' '}
          {maxBuy ? fromWei(maxBuy[0].toString()).toString() : '0'} ETH
        </Trans>
      </ThemedText.TextSecondary>
      <Box mt="16px">
        <StyledInputBox>
          <Box display="flex" justifyContent="flex-end" alignItems="center" gap="8px">
            <NumericalInput value={buyAmount} onUserInput={onUserInput} />
            <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
              ETH
            </ThemedText.TextPrimary>
          </Box>
        </StyledInputBox>
      </Box>
      {/* <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mt="12px">
        <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
          <Trans>Receive</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
          0.2 SOL
        </ThemedText.TextPrimary>
      </Box> */}
      <Box mt="10px">
        <StyledCreateBtn disabled={attemptingTxn} onClick={handlerCreate}>
          <ThemedText.TextPrimary color="white" fontSize={16} fontWeight={700} mt="-8px">
            <Trans>{attemptingTxn ? <Pending /> : 'Create'}</Trans>
          </ThemedText.TextPrimary>
        </StyledCreateBtn>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mt="14px">
        <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
          <Trans>Cost to deploy</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
          {createFee ? fromWei(createFee[0].toString()).toString() : '0'} ETH
        </ThemedText.TextPrimary>
      </Box>
    </StyledDialog>
  )
}
