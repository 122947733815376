import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useSwapHistoryQuery } from 'graphql/thegraph/LaunchpadQuery'
import styled from 'styled-components/macro'
import { ExternalLink, ThemedText } from 'theme'
import { ellipseAddressAdd0x, shortenAddress } from 'utils'
import { countZeros } from 'utils/countZeros'
import { computeNumUnit } from 'utils/formatNum'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import { handleCreatedTime } from '../InfoList'

const StyledTradeList = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledTradeItem = styled(Box)`
  width: 100%;
  background: ${({ theme }) => theme.themeBg};
  border-radius: 16px;
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  .item-top {
    padding: 12px 12px 10px 8px;
    border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  }
  .item-bottom {
    padding: 8px 12px 8px 8px;
  }
`

const StyledBox = styled(Box)`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledBuy = styled(StyledBox)`
  background: ${({ theme }) => theme.long};
`

const StyledSell = styled(StyledBox)`
  background: ${({ theme }) => theme.short};
`

export default function TradeListM({ infoData }: any) {
  const { data: historyList } = useSwapHistoryQuery(infoData.launchPoolInfo.pool.id)
  const { chainId } = useActiveChainId()
  const isToken0: boolean = infoData.launchPoolInfo.isToken0

  return (
    <StyledTradeList>
      {historyList &&
        historyList.map((item: any, i: any) => {
          return (
            <StyledTradeItem key={i}>
              <div className="item item-top">
                <Box display="flex" alignItems="center" gap="8px">
                  {item.type == 0 ? (
                    <StyledBuy>
                      <ThemedText.TextPrimary color="white" fontSize={14} fontWeight={700}>
                        <Trans>B</Trans>
                      </ThemedText.TextPrimary>
                    </StyledBuy>
                  ) : (
                    <StyledSell>
                      <ThemedText.TextPrimary color="white" fontSize={14} fontWeight={700}>
                        <Trans>S</Trans>
                      </ThemedText.TextPrimary>
                    </StyledSell>
                  )}
                  <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                    {shortenAddress(item.recipient)}
                  </ThemedText.TextSecondary>
                </Box>
                <ThemedText.TextTertiary fontSize={12} fontWeight={400}>
                  <Trans>{handleCreatedTime(item.timestamp)}</Trans>
                </ThemedText.TextTertiary>
              </div>

              <div className="item item-bottom">
                <Box display="flex" alignItems="center" gap="24px">
                  <Box display="flex" alignItems="center" gap="4px">
                    <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                      {countZeros(Math.abs(isToken0 ? item.amount1 : item.amount0))}
                    </ThemedText.TextPrimary>
                    <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                      ETH
                    </ThemedText.TextSecondary>
                  </Box>
                  <Box display="flex" alignItems="center" gap="4px">
                    <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                      {computeNumUnit(Math.abs(!isToken0 ? item.amount1 : item.amount0))}
                    </ThemedText.TextPrimary>
                    <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                      {infoData.symbol}
                    </ThemedText.TextSecondary>
                  </Box>
                </Box>
                <ExternalLink href={getExplorerLink(chainId, item.transaction, ExplorerDataType.TRANSACTION)}>
                  <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                    <Trans> {ellipseAddressAdd0x(item.transaction)}</Trans>
                  </ThemedText.TextPrimary>
                </ExternalLink>
              </div>
            </StyledTradeItem>
          )
        })}
    </StyledTradeList>
  )
}
