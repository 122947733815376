import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as CopyIcon } from 'assets/imgs/marketCap/copy.svg'
import { ReactComponent as UserIcon } from 'assets/imgs/marketCap/user.svg'
import Column from 'components/Column'
import { MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { ToastSuccess } from 'components/Toast'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { useAtom } from 'jotai'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { formatAmount } from 'utils/formatAmout'
import { computeNumUnit } from 'utils/formatNum'

import { useDataList, useMarketCapLength, usePoolLiquidity } from './hooks'
import { StyledParticipantsIcon } from './InfoList'
import { LinkBox2 } from './LinkBox'
import { sortPageAtom } from './state'
import { StyledBorderBox } from './StyledBorderBox'
import { StyledPagination } from './StyledPagination'

const StyledMarketList = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const StyledMarketItem = styled(StyledBorderBox)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  .desc {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
  &:hover {
    border: 1px solid #1ea7e4;
    cursor: pointer;
  }
`

export const StyledCopyIcon = styled(CopyIcon)`
  path {
    fill: ${({ theme }) => theme.textTertiary};
  }
  &:hover {
    cursor: pointer;
    path {
      fill: ${({ theme }) => theme.primaryBtn_hover};
    }
  }
`

const StyledUserIcon = styled(UserIcon)`
  path {
    fill: ${({ theme }) => theme.textTertiary};
  }
`

export function LoadingRows() {
  return (
    <>
      <MediumLoadingBubble />
      <MediumLoadingBubble />
      <MediumLoadingBubble />
      <MediumLoadingBubble />
      <MediumLoadingBubble />
      <MediumLoadingBubble />
    </>
  )
}

export default function MarketList() {
  const data = useDataList()

  const count = useMarketCapLength()

  const [marketCapSortPage, setMarketCapSortPage] = useAtom(sortPageAtom)
  const handleNextPage = (e: any, p: any) => {
    setMarketCapSortPage(p)
  }

  return (
    <div>
      <StyledMarketList>
        {!data ? <LoadingRows /> : data?.map((item: any, i: number) => <MarketItem item={item} key={i} />)}
      </StyledMarketList>
      <StyledPagination style={{ marginTop: '8px' }} count={count} onChange={handleNextPage} />
    </div>
  )
}

function MarketItem({ item }: any) {
  const { wethBalance, tokenBalance } = usePoolLiquidity(item.launchPoolInfo.pool.id, item.address)

  const [, setCopied] = useCopyClipboard()
  const handlerCopy = useCallback(
    (event: any) => {
      event.stopPropagation()
      event.preventDefault()
      setCopied(item.address ?? '')
      ToastSuccess('Copied to clipboard')
    },
    [item.address, setCopied]
  )

  const navigate = useNavigate()
  const handlerNavigateTo = (address: string) => {
    navigate(`/infoDetail?token=${address}`)
  }

  return (
    <StyledMarketItem onClick={() => handlerNavigateTo(item.address)}>
      <Box display="flex" alignItems="center" gap="8px" justifyContent="space-between" width="100%">
        <Box display="flex" alignItems="center" gap="14px" justifyContent="flex-start" width="100%">
          <img src={item?.metadata?.image_url} width="78px" height="78px" />
          <Column gap="8px" align="flex-start" justify="center">
            <Row gap="4px">
              <ThemedText.TextPrimary fontSize={18} fontWeight={800}>
                <Trans>{item.symbol}</Trans>
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                <Trans>{item.name}</Trans>
              </ThemedText.TextSecondary>
            </Row>
            <Row gap="8px">
              <ThemedText.TextPrimary fontSize={12} fontWeight={400}>
                <Trans>{shortenAddress(item.address)}</Trans>
              </ThemedText.TextPrimary>
              <StyledCopyIcon onClick={handlerCopy} />
            </Row>
            <Row gap="4px">
              <StyledUserIcon />
              <ThemedText.TextPrimary fontSize={12} fontWeight={400}>
                <Trans>{shortenAddress(item.createdOrigin)}</Trans>
              </ThemedText.TextPrimary>
            </Row>
          </Column>
        </Box>
        <Box display="flex" alignItems="center" gap="4px">
          <StyledParticipantsIcon />
          <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
            {formatAmount(item.userCount, 0, true)}
          </ThemedText.TextPrimary>
        </Box>
      </Box>
      <Box display="flex" alignItems="flex-start" gap="8px" justifyContent="space-between" width="100%" mt="16px">
        <Column gap="8px" align="flex-start">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Market Cap</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={24} fontWeight={700}>
            ${computeNumUnit(item.marketCap)}
          </ThemedText.TextPrimary>
        </Column>
        <Column gap="6px" align="flex-end">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Liquidity</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
            {computeNumUnit(wethBalance?.toExact())} ETH
          </ThemedText.TextPrimary>
          <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
            {computeNumUnit(tokenBalance?.toExact())} {item.symbol}
          </ThemedText.TextPrimary>
          {/* <Box width={200}>
            <CapProgressBar value={20} />
          </Box> */}
        </Column>
      </Box>
      <ThemedText.TextSecondary fontSize={12} fontWeight={400} mt="16px" className="desc">
        <Trans>{item?.metadata?.description}</Trans>
      </ThemedText.TextSecondary>
      <Box mt="18px">
        <LinkBox2 metadata={item?.metadata} />
      </Box>
    </StyledMarketItem>
  )
}
