import { useEthPriceUSD, useQueryAllData, useRoxPriceETH } from 'hooks/useQueryLiquidity'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setEthPrice, setLaunchpadQueryList, setQueryList, setRoxPrice } from './actions'
import { useLaunchpadQuery } from 'graphql/thegraph/LaunchpadQuery'

export default function Updater() {
  const dispatch = useDispatch()

  const launchpadData = useLaunchpadQuery()

  // const ethPrice = useEthPriceUSD()
  // const roxPrice = useRoxPriceETH()

  useEffect(() => {
    dispatch(setLaunchpadQueryList({ list: launchpadData }))
  }, [launchpadData, dispatch])

  // useEffect(() => {
  //   dispatch(setEthPrice({ price: ethPrice }))
  // }, [dispatch, ethPrice])

  // useEffect(() => {
  //   dispatch(setRoxPrice({ price: roxPrice }))
  // }, [dispatch, roxPrice])
  return null
}
