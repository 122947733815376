import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import MegaBg from 'assets/imgs/mega/megaBg.svg'
import MegaData from 'assets/imgs/mega/megaData.svg'
import MegaDataDark from 'assets/imgs/mega/megaData-dark.svg'
import MegaMData from 'assets/imgs/mega/megaData-m.svg'
import MegaMDataDark from 'assets/imgs/mega/megaData-m-dark.svg'
import Column from 'components/Column'
import { PageWrapper } from 'components/PageWrapper'
import styled, { useTheme } from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'

const StyledLine = styled.div`
  border-bottom: 2px dashed ${({ theme }) => theme.primaryBorder};
`

const StyledMegaContainer = styled.div`
  width: 624px;
  max-width: 100%;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    width: 100%;
  }
`

const StyledPageWrapper = styled(PageWrapper)`
  height: 100%;
  background-image: url(${MegaBg});
  background-size: 1013px 689px;
  background-position: center;
  background-repeat: no-repeat;
`

export default function Mega() {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const isVerySmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)

  return (
    <StyledPageWrapper className="mega">
      <ThemedText.TextPrimary fontWeight={800} fontSize={48} color="primaryTitle">
        <Trans>Mega Blast Gold Rewards</Trans>
      </ThemedText.TextPrimary>
      <ThemedText.TextSecondary fontWeight={300} fontSize={16} mt="12px">
        <Trans>
          After this round has ended, Blast Gold will be distributed based on Market Capitilzation Rankings.
        </Trans>
      </ThemedText.TextSecondary>

      <Box
        display="flex"
        flexDirection={isVerySmallScreen ? 'column' : 'row'}
        alignItems={isVerySmallScreen ? 'flex-start' : 'center'}
        justifyContent="flex-start"
        mt="30px"
        gap={isVerySmallScreen ? '16' : isSmallScreen ? '32px' : '74px'}
      >
        <Column align={isVerySmallScreen ? 'flex-start' : 'center'} gap="8px">
          <ThemedText.TextPrimary fontWeight={800} fontSize={40} className="active-gradient-text">
            <Trans>Top 10</Trans>
          </ThemedText.TextPrimary>
          <ThemedText.TextSecondary fontWeight={400} fontSize={16}>
            <Trans>Projects</Trans>
          </ThemedText.TextSecondary>
        </Column>
        <StyledMegaContainer>
          <ul>
            <li>
              <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
                <Trans>Will share 9,100 gold amongst them.</Trans>
              </ThemedText.TextPrimary>
            </li>
            <li>
              <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
                <Trans>Token creators receive 40% of this amount.</Trans>
              </ThemedText.TextPrimary>
            </li>
            <li>
              <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
                <Trans>The remaining 60% will be distributed amongst token holders.</Trans>
              </ThemedText.TextPrimary>
            </li>
          </ul>
        </StyledMegaContainer>
      </Box>
      <Box mt="50px">
        <StyledLine />
      </Box>
      <Box
        display="flex"
        flexDirection={isVerySmallScreen ? 'column' : 'row'}
        alignItems={isVerySmallScreen ? 'flex-start' : 'center'}
        justifyContent="flex-start"
        mt="40px"
        gap={isVerySmallScreen ? '16' : isSmallScreen ? '32px' : '74px'}
      >
        <Column align={isVerySmallScreen ? 'flex-start' : 'center'} gap="8px">
          <ThemedText.TextSecondary fontWeight={400} fontSize={16}>
            <Trans>Projects Ranked</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontWeight={800} fontSize={40}>
            <Trans>11-100</Trans>
          </ThemedText.TextPrimary>
        </Column>
        <StyledMegaContainer>
          <ul>
            <li>
              <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
                <Trans>Will share 900 gold amongst them.</Trans>
              </ThemedText.TextPrimary>
            </li>
            <li>
              <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
                <Trans>
                  Token creators will receive 100% of this amount, meaning every project dev amongst the top 11-100 will
                  get 10 gold.
                </Trans>
              </ThemedText.TextPrimary>
            </li>
          </ul>
        </StyledMegaContainer>
      </Box>
      <Box mt="40px">
        <StyledLine />
      </Box>
      <Column gap="20px" mt="24px">
        <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
          <Trans>The distribution specifics are detailed in the chart below:</Trans>
        </ThemedText.TextPrimary>
        {isVerySmallScreen ? (
          <img src={theme.darkMode ? MegaMDataDark : MegaMData} alt="" />
        ) : (
          <img src={theme.darkMode ? MegaDataDark : MegaData} alt="" />
        )}
      </Column>
    </StyledPageWrapper>
  )
}
