import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { Currency, Percent } from '@uniswap/sdk-core'
import { CurrencyAmount } from '@uniswap/sdk-core'
import { Multicall } from '@uniswap/v3-sdk'
import QUOTERABI from 'abis/quoter.json'
import SwapRouterABI from 'abis/SwapRouter.json'
import TradeBtn from 'assets/imgs/marketCap/tradeBtn.svg'
import TradeBtnHover from 'assets/imgs/marketCap/tradeBtn-hover.svg'
import BigNumber from 'bignumber.js'
import { BaseButton, Pending } from 'components/Button'
import { useDynamicApprove } from 'components/DynamicApprove'
import MaxTab from 'components/MaxTab'
import NumericalInput from 'components/NumericalInput'
import { SettingsTab2 } from 'components/Settings'
import { ToastError } from 'components/Toast'
import { useActiveChainId } from 'connection/useActiveChainId'
import { SWAP_ROUTER_ADDRESSES } from 'constants/addresses'
import { Interface } from 'ethers/lib/utils'
import { useCurrency } from 'hooks/Tokens'
import { useQuoter } from 'hooks/useContract'
import usePrevious from 'hooks/usePrevious'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { TradeState } from 'state/routing/types'
import { Field, replaceSwapState } from 'state/swap/actions'
import { useDerivedSwapInfo } from 'state/swap/hooks'
import swapReducer, { initialState as initialSwapState, SwapState } from 'state/swap/reducer'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN, fromWei } from 'utils/bn'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { formatAmount } from 'utils/formatAmout'
import { handlerError } from 'utils/formatError'
import { useContractRead } from 'wagmi'

import { StyledBorderBox } from '../StyledBorderBox'

const StyledSwapBox = styled(StyledBorderBox)`
  padding: 16px;
`

const StyledTab = styled(Box)`
  flex: 1;
  padding: 2px;
  border-radius: 8px;
  height: 40px;
  background: ${({ theme }) => theme.backgroundInteractive};
  display: flex;
  .tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
  .tabActive-buy {
    background: ${({ theme }) => theme.long};
  }
  .tabActive-sell {
    background: ${({ theme }) => theme.short};
  }
`

const StyledInputTab = styled(Box)`
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  .tab-item {
    height: 30px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`

export const StyledInputBox = styled(Box)`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  padding: 16px;
`

const StyledTradeBtn = styled(BaseButton)`
  width: 308px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  background-image: url(${TradeBtn});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  &:hover {
    background-image: url(${TradeBtnHover});
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  &:disabled {
    cursor: not-allowed;
  }
`
export const numberToHex = (val: BigNumber.Value, decimals = 18) => {
  const num_str = BigNumber(val).multipliedBy(BigNumber(10).pow(decimals))
  const x = new BigNumber(num_str)
  return x.toFixed(0)
}

export default function Swap({ infoData }: { infoData: any }) {
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const [curr, setCurr] = useState(0)
  const [swapAmount, setSwapAmount] = useState('')
  const currList = ['BUY', 'SELL']
  const [spreadL, setSpreadL] = useState<Percent>(new Percent(10, 100))
  // const isToken0: boolean = infoData.launchPoolInfo.isToken0
  const { chainId, account, provider } = useActiveChainId()
  const native = useNativeCurrency(chainId)
  const ethBalance = useCurrencyBalance(account ?? undefined, native)
  const tokenCurrency = useCurrency(infoData.address)
  const tokenBalance = useCurrencyBalance(account ?? undefined, tokenCurrency ?? undefined)

  const prefilledState: Partial<SwapState> = {
    [Field.INPUT]: {
      currencyId: curr == 0 ? native.wrapped.address : tokenCurrency?.wrapped.address,
    },
    [Field.OUTPUT]: {
      currencyId: curr == 0 ? tokenCurrency?.wrapped.address : native.wrapped.address,
    },
  }

  const onUserInput = (amount: any) => {
    setSwapAmount(amount)
  }

  const QuoterContract = useQuoter()
  const [state, dispatch] = useReducer(swapReducer, { ...initialSwapState, ...prefilledState })
  const previousConnectedChainId = usePrevious(chainId)
  const previousPrefilledState = usePrevious(prefilledState)
  useEffect(() => {
    const combinedInitialState = { ...initialSwapState, ...prefilledState }
    const chainChanged = previousConnectedChainId && previousConnectedChainId !== chainId
    const prefilledInputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.INPUT]?.currencyId !== prefilledState?.[Field.INPUT]?.currencyId
    const prefilledOutputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.OUTPUT]?.currencyId !== prefilledState?.[Field.OUTPUT]?.currencyId
    if (chainChanged || prefilledInputChanged || prefilledOutputChanged) {
      dispatch(
        replaceSwapState({
          ...initialSwapState,
          ...prefilledState,
          field: combinedInitialState.independentField ?? Field.INPUT,
          inputCurrencyId: combinedInitialState.INPUT.currencyId ?? undefined,
          outputCurrencyId: combinedInitialState.OUTPUT.currencyId ?? undefined,
        })
      )
    }
  }, [chainId, prefilledState, previousConnectedChainId, previousPrefilledState])
  const swapInfo = useDerivedSwapInfo(state, chainId, swapAmount)
  const {
    trade: { state: tradeState, trade },
    inputError: swapInputError,
  } = swapInfo
  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [
      tradeState === TradeState.NO_ROUTE_FOUND,
      tradeState === TradeState.LOADING,
      tradeState === TradeState.LOADING && Boolean(trade),
    ],
    [trade, tradeState]
  )

  const amountCurrency: CurrencyAmount<Currency> | undefined = useMemo(() => {
    if (!tokenCurrency || !swapAmount) return undefined
    return CurrencyAmount.fromRawAmount(tokenCurrency, numberToHex(swapAmount))
  }, [tokenCurrency, swapAmount])

  const { DynamicApprove, isApproved } = useDynamicApprove(
    [amountCurrency],
    SWAP_ROUTER_ADDRESSES[chainId] ?? undefined
  )

  const quoteParams = useMemo(() => {
    if (!swapAmount || swapAmount == '0' || swapAmount == '') return undefined
    if (curr == 0) {
      return [native.wrapped.address, infoData.address, '100', numberToHex(swapAmount), '0']
    } else {
      return [infoData.address, native.wrapped.address, '100', numberToHex(swapAmount), '0']
    }
  }, [curr, infoData.address, native.wrapped.address, swapAmount])

  //
  // function quoteExactInputSingle(
  //     address tokenIn,
  //     address tokenOut,
  //     uint24 fee,
  //     uint256 amountIn,
  //     uint160 sqrtPriceLimitX96
  // ) public override returns (uint256 amountOut)
  //
  const { data: esreceiveAmount }: any = useContractRead({
    address: QuoterContract?.address as any,
    abi: QUOTERABI.abi,
    functionName: quoteParams ? 'quoteExactInputSingle' : undefined,
    args: quoteParams as any,
  })
  const INTERFACE: Interface = new Interface(SwapRouterABI.abi)

  const addTransaction = useTransactionAdder()
  const DoSwap = async () => {
    if (!account || !provider || !chainId) return
    if (curr == 1 && !isApproved) return
    const InputETH = curr == 0
    let params
    if (curr == 0) {
      params = {
        tokenIn: native.wrapped.address,
        tokenOut: infoData.address,
        fee: BN(100).toFixed(),
        recipient: account,
        deadline: Math.round(Number(new Date()) / 1000) + 5000,
        amountIn: numberToHex(swapAmount),
        amountOutMinimum:
          esreceiveAmount && spreadL
            ? BN(esreceiveAmount)
                .multipliedBy(BN(100).minus(spreadL.toFixed(2)))
                .div(100)
                .toFixed(0)
            : '0',
        sqrtPriceLimitX96: '0',
      }
    } else {
      params = {
        tokenIn: infoData.address,
        tokenOut: native.wrapped.address,
        fee: BN(100).toFixed(),
        recipient: account,
        deadline: Math.round(Number(new Date()) / 1000) + 5000,
        amountIn: numberToHex(swapAmount),
        amountOutMinimum:
          esreceiveAmount && spreadL
            ? BN(esreceiveAmount)
                .multipliedBy(BN(100).minus(spreadL.toFixed(2)))
                .div(100)
                .toFixed(0)
            : '0',
        sqrtPriceLimitX96: '0',
      }
    }
    const calldatas: string[] = []

    const add_data = INTERFACE.encodeFunctionData('exactInputSingle', [params])
    calldatas.push(add_data)
    if (InputETH) {
      const refundETH_data = INTERFACE.encodeFunctionData('refundETH')
      calldatas.push(refundETH_data)
    }
    setAttemptingTxn(true)
    const txn: { to: string; data: string; value: string } = {
      to: SWAP_ROUTER_ADDRESSES[chainId],
      data: Multicall.encodeMulticall(calldatas),
      value: InputETH ? numberToHex(swapAmount) : '0',
    }
    provider
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(estimate) || 4000000,
        }
        return provider
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            setAttemptingTxn(false)
            addTransaction(response, {
              type: TransactionType.EXECUTE_ORDERLIST,
              token0Address: '',
              token1Address: '',
            })
            setTxHash(response.hash)
          })
      })
      .catch((error) => {
        setAttemptingTxn(false)
        setTxError(handlerError(error))
        ToastError(handlerError(error))
        console.error('swap error:', error)
      })
  }

  // const { result, error } = useSingleCallResult(QuoterContract ?? undefined, 'quoteExactInputSingle', quoteParams ?? [undefined])

  const onUserMaxTab = useCallback(
    (value: number | string) => {
      const selectedCurrencyBalance = curr == 0 ? ethBalance : tokenBalance
      if (!selectedCurrencyBalance || Number(selectedCurrencyBalance.toExact()) == 0) return
      const max = BN(selectedCurrencyBalance.toExact()).multipliedBy(value).toFixed(18)
      onUserInput(max)
    },
    [ethBalance, tokenBalance, curr]
  )

  const isInsufficientBalance = useMemo(() => {
    if (!swapAmount || swapAmount == '0' || swapAmount == '') return false
    const selectedCurrencyBalance = curr == 0 ? ethBalance : tokenBalance
    if (!selectedCurrencyBalance || Number(selectedCurrencyBalance) == 0) return false
    if (BN(swapAmount).gt(BN(selectedCurrencyBalance?.toExact()))) {
      return true
    }
    return false
  }, [curr, ethBalance, tokenBalance, swapAmount])

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <StyledSwapBox>
        <Box display="flex" alignItems="center" width="100%" gap="8px">
          <StyledTab>
            {currList.map((item, i) => (
              <Box
                key={i}
                className={`tab flex-1 ${curr == i && curr == 0 && 'tabActive-buy'} ${
                  curr == i && curr == 1 && 'tabActive-sell'
                }`}
                onClick={() => setCurr(i)}
                style={{
                  cursor: 'pointer',
                }}
              >
                {curr == i ? (
                  <ThemedText.TextPrimary color="white" fontSize={14} fontWeight={700}>
                    <Trans>{item}</Trans>
                  </ThemedText.TextPrimary>
                ) : (
                  <ThemedText.TextSecondary fontSize={14} fontWeight={700}>
                    <Trans>{item}</Trans>
                  </ThemedText.TextSecondary>
                )}
              </Box>
            ))}
          </StyledTab>
          <SettingsTab2 autoSlippage={spreadL} chainId={chainId} trade={undefined} setFunc={setSpreadL} />
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt="16px">
          {/* <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Switch to RFD</Trans>
          </ThemedText.TextSecondary> */}
          {/* <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>SET Max Slippage</Trans>
          </ThemedText.TextSecondary> */}
        </Box>
        <Box mt="8px">
          <StyledInputBox>
            <Box display="flex" justifyContent="flex-end">
              <ThemedText.TextSecondary fontSize={14} fontWeight={400} mb="14px">
                Balance:{formatAmount(curr == 0 ? ethBalance?.toFixed() : tokenBalance?.toFixed(), 4, true)}{' '}
                {curr == 0 ? 'ETH' : infoData.symbol}
              </ThemedText.TextSecondary>
            </Box>
            <NumericalInput value={swapAmount} onUserInput={onUserInput} />
            <Box>
              <MaxTab onChange={onUserMaxTab} />
            </Box>
          </StyledInputBox>
        </Box>
        {/* <StyledInputTab>
          {inputTabList.map((tab, i) => (
            <Box display="flex" justifyContent="center" alignItems="center" key={i} className="tab-item">
              <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                <Trans>{tab.desc} USDB</Trans>
              </ThemedText.TextSecondary>
            </Box>
          ))}
        </StyledInputTab> */}
        <Box display="flex" justifyContent="space-between" alignItems="center" m="16px 0">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Receive</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
            {formatAmount(fromWei(esreceiveAmount), 4, true)} {curr == 1 ? 'ETH' : infoData.symbol}
          </ThemedText.TextPrimary>
        </Box>
        {curr == 1 && !isApproved && <DynamicApprove />}
        <Box display="flex" justifyContent="center" mt="16px">
          <StyledTradeBtn
            onClick={DoSwap}
            disabled={(curr == 1 && !isApproved) || isInsufficientBalance || routeNotFound || attemptingTxn}
          >
            <ThemedText.TextPrimary color="white" fontSize={16} fontWeight={700} mt="-8px">
              <Trans>
                {isInsufficientBalance ? (
                  'Insufficient balance '
                ) : routeNotFound ? (
                  'Insufficient liquidity'
                ) : attemptingTxn ? (
                  <Pending />
                ) : (
                  'Trade'
                )}
              </Trans>
            </ThemedText.TextPrimary>
          </StyledTradeBtn>
        </Box>
      </StyledSwapBox>
    </Box>
  )
}
