import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import Column from 'components/Column'
import { PageWrapper } from 'components/PageWrapper'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const StyledHow = styled(Box)`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  .item {
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.primaryBorder};
    padding: 32px;
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    gap: 32px;
    grid-template-columns: repeat(1, 1fr);
    .item {
      padding: 24px;
    }
  }
`

export default function How() {
  return (
    <PageWrapper className="how">
      <ThemedText.TextPrimary fontWeight={800} fontSize={48} color="primaryTitle">
        <Trans>How it works:</Trans>
      </ThemedText.TextPrimary>
      <ThemedText.TextSecondary fontWeight={300} fontSize={16} mt="12px">
        <Trans>
          Each coin on RogueX-LFG is a fair-launch with no presale and no team allocation. Rugs are prevented by
          ensuring all liquidity is burnt and that there are no outstanding tokens outside of what is bought from the
          initial pool.
        </Trans>
      </ThemedText.TextSecondary>
      <StyledHow>
        <div className="item">
          <Box display="flex" alignItems="center" gap="20px">
            <ThemedText.TextPrimary fontWeight={800} fontSize={20}>
              <Trans>Creator</Trans>
            </ThemedText.TextPrimary>
          </Box>
          <Column width="100%" align="flex-start" gap="24px" mt="32px">
            <Column width="100%" align="flex-start" gap="8px">
              <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                <Trans>Step 1:</Trans>
              </ThemedText.TextPrimary>
              <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
                <Trans>Choose a token name, ticker (i.e. $MEOW) & description for your token.</Trans>
              </ThemedText.TextPrimary>
            </Column>
            <Column width="100%" align="flex-start" gap="8px">
              <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                <Trans>Step 2:</Trans>
              </ThemedText.TextPrimary>
              <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
                <Trans>
                  Pay 0.001 ETH to create the token. All LP will be burnt automatically burnt upon token creation.
                </Trans>
              </ThemedText.TextPrimary>
            </Column>
            <Column width="100%" align="flex-start" gap="8px">
              <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                <Trans>Step 3:</Trans>
              </ThemedText.TextPrimary>
              <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
                <Trans>
                  Share the link and spread the word of your token's creation and let the free market do its work!
                </Trans>
              </ThemedText.TextPrimary>
            </Column>
          </Column>
        </div>
        <div className="item">
          <Box display="flex" alignItems="center" gap="20px">
            <ThemedText.TextPrimary fontWeight={800} fontSize={20}>
              <Trans>User</Trans>
            </ThemedText.TextPrimary>
          </Box>
          <Column width="100%" align="flex-start" gap="24px" mt="32px">
            <Column width="100%" align="flex-start" gap="8px">
              <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                <Trans>Step 1:</Trans>
              </ThemedText.TextPrimary>
              <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
                <Trans>Pick a token that you like.</Trans>
              </ThemedText.TextPrimary>
            </Column>
            <Column width="100%" align="flex-start" gap="8px">
              <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                <Trans>Step 2:</Trans>
              </ThemedText.TextPrimary>
              <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
                <Trans>Buy the token via RogueX</Trans>
              </ThemedText.TextPrimary>
            </Column>
            <Column width="100%" align="flex-start" gap="8px">
              <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                <Trans>Step 3:</Trans>
              </ThemedText.TextPrimary>
              <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
                <Trans>Sell at any time to lock in your profits or losses</Trans>
              </ThemedText.TextPrimary>
            </Column>
            <Column width="100%" align="flex-start" gap="8px">
              <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                <Trans>Step 4:</Trans>
              </ThemedText.TextPrimary>
              <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
                <Trans>
                  Top10 coin holders will earn{' '}
                  <a href=" " target="_blank">
                    Blast golds
                  </a>{' '}
                  by the 3rd round of RougeX
                </Trans>
              </ThemedText.TextPrimary>
            </Column>
          </Column>
        </div>
      </StyledHow>
    </PageWrapper>
  )
}
